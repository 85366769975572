<template>
    <div class="application">
        <!-- 报名参加 -->
        <div class="center">
            <aside-part></aside-part>
            <div class="content">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>报名参加</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="title">
                    <div class="title_zh">报名参加</div>
                    <div class="title_en">sign up for</div>
                </div>
                <a :href=getImgView(Alldata.signUpUrl) download="Application.pdf">下载PDF</a>
                <div class="tips" style="align-items: center;">
                    <div style="font-weight: bold;">报名方式：</div>
                    <div class="item">
                        填写<el-button @click="getPDF" style="margin: 0 5px;" type="primary" plain>报名表</el-button>发送到指定邮箱
                    </div>
                </div>
                <div class="tips">
                    <div style="font-weight: bold;">报名须知：</div>
                    <div class="item">
                        1、会议免注册费，会务组统一安排就餐，参会代表住宿费、交通费自理。<br />
                        2、发送报名表至大会指定邮箱：iscest@usp-pl.com
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import asidePart from '@/components/asidePart.vue';
export default {
    name: 'application',
    components: {
        asidePart
    },
    mounted() {
       this.getDate();
    },
    methods: {
        getPDF() {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', '/pdf/Application.pdf', true);
            xhr.responseType = 'blob';
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var blob = new Blob([xhr.response], { type: 'application/octet-stream' });
                    var url = URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = 'Application.pdf';
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            };
            xhr.send(null);
        }
    }
}
</script>

<style lang="less" scoped>
.center {
    display: flex;
    align-items: top;

    .content {
        padding: 30px;
        width: calc(100% - 60px);

        .title {
            margin-top: 20px;
        }

        .tips {
            margin-top: 20px;
            display: flex;
            align-items: top;
            line-height: 30px;

            .item {

                font-size: 14px;
                color: #7e7e7e;
            }
        }
    }
}
</style>